button.ant-btn.ant-btn-primary {
  font-weight: bold;
}

nz-table tr:last-of-type > td {
  border-bottom: none;
}

nz-table.uppercase-header th {
  text-transform: uppercase;
}

.ant-modal-header{
  text-align: center;
}

.loadmore {
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
  height: 32px;
  line-height: 32px;
}
